import React from "react"

const SeoEmb = ({ title, canonical }) => {
  return (
    <>
      <title>{title}</title>
      {/* <link rel="canonical" href={canonical}></link> */}
      <meta name="robots" content="noindex, follow" />
    </>
  )
}

export default SeoEmb
