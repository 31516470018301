import React from "react"
import { createGlobalStyle } from "styled-components"
import { Script } from "gatsby"

const GlobalStyle = createGlobalStyle`
  .adthrive-comscore.adthrive-footer-message {
    display: none;
  }
  .adthrive-ad.adthrive-footer.adthrive-footer-1.adthrive-ad-cls.adthrive-sticky {
    display: none;
  }
`

const DisableAd = () => {
  return (
    <>
      {typeof window !== "undefined" &&
        process.env.GATSBY_BUILD_CONTEXT !== "development" && (
          <Script async id="remove-adthrive-ad">
            {`adthrive.cmd.push(function() {
                adthrive.disableAds()
              })`}
          </Script>
        )}
      <GlobalStyle />
    </>
  )
}

export default DisableAd
